// @flow
import type { Course as CourseType } from "redux/api-types/Course";
import CourseRecord from "./CourseRecord";
import { createCourseUrl } from "shared/utilities/course";

export const parseCourse = (course: CourseType): {} => {
  const { id, attributes } = course;
  const {
    capacity,
    classroomHours,
    curriculumId,
    curriculumModuleId,
    description,
    futureOccurrencesCount,
    grantsCCW,
    hasLiveFire,
    isArchived,
    isMultiday,
    location,
    name,
    notes,
    pastOccurrencesCount,
    price,
    rangeHours,
    registration,
    instructorId,
    hasPaymentEnabled,
  } = attributes;
  const link = createCourseUrl(id, name);
  const editLink = `/edit-course/${id}`;
  const record = new CourseRecord({
    capacity,
    classroomHours,
    curriculumId,
    curriculumModuleId,
    description,
    editLink,
    futureOccurrencesCount,
    grantsCCW,
    hasLiveFire,
    id,
    isArchived,
    isMultiday,
    link,
    location,
    name,
    notes,
    pastOccurrencesCount,
    price,
    rangeHours,
    registration,
    instructorId,
    hasPaymentEnabled,
  });

  return record;
};
